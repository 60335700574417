.modal-btn-container{
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}
.model{
    
}
.btn-mdl{
    margin: 1px;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: 0px;
}
.scrollvalues{
    border: 1px solid #2a436574;
    width: 400px;
    height: 500px;
    overflow: auto;
    padding: 10px;
}