.background-uploadImage {
  background-color: rgba(223, 232, 246, 0.769);
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.collection-img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.form-input {
  margin: 50px;
  width: 200px;
  height: 300px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.form-input img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 200px;
  height: 250px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: fill;
}

.form-input input {
  display: none;
}

.form-input label {
  display: block;
  width: 210px;
  height: 55px;
  line-height: 50px;
  text-align: center;
  background: #1172c2;
  color: #fff;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  text-transform: Uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.text-upload-images-header {
  font-size: 34px;
  margin: 20px;
  text-align: center;
}

.upload-initial-images-note {
  text-align: center;
  font-size: 16px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-finish-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.upload-text {
  color: #ddd;
}
