body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.helpDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1000;
}

.helpDiv:hover {
  cursor: pointer;
}

.helpDiv1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.helpDiv1:hover {
  cursor: pointer;
}

.wpImage {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.helpText {
  color: #fff;
}
