.GeoFence-container{
    min-height: 100vh;
    border: 1px saddlebrown solid;
}

.GeoFence-header{
    height: 60px;
    background-color: rgb(45,55,72);
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 900;
    color: white;
    margin-top: 0px;
}

.GeoFence-body{
    padding: 10px;
}
.GeoFence-body>h2,p{
    padding: 0px;
    margin: 0px;
    margin-right: 5px;
}
.GeoFence-body>iframe{
    margin-top: 10px;
    margin-bottom: 30px;
}

.flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.flex-row>button{

    border-radius: 10px;

    margin: 5px;
    margin-top: 0px;
    padding: 5px;

    height: 38px;
    max-width: 100px;

    border: none;

    flex: 2;

    background-color: rgb(45,55,72);

    color: white;
    font-weight: 600;
    text-transform:uppercase;
}

.box-btn{

    border-radius: 10px;

    margin: 5px;
    margin-top: 0px;
    padding: 5px;


    max-width: 25px;
    height: 25px;
    border: 1px solid silver;

    flex: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: silver;

    color: rgb(75, 73, 73);
    font-weight: 600;
    text-transform:uppercase;

}

.box-btn-active{
    background-color: rgb(39,167,209);
    color: white;
}

.plus-btn{
    background-color:rgb(45,55,72); 
    color: white;
    font-weight: 600;
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;

    font-size: larger;

    border-radius: 2px;

    cursor: pointer;
}

.optional-add{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-top: 30px;

    font-weight: 600;
}
.plus-btn{
    margin-right: 10px;
}

.submit-geo{
    width: 90%;
    margin: 0 auto;
    background-color:rgb(45,55,72); 
    color: white;
    font-weight:500;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding: 8px 5px;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;
}
.btn-geofence-style{
    cursor: pointer;
}
.geo-body-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90vh;
}