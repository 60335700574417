.modal-header{
    height: 50px;
    background-color: rgb(45,55,72);
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 900;
    color: white;
    margin-top: 0px;
}
.cyan{
    color: #27A7D1;
}
.modal{
    position: relative;
    top:30%;
    bottom: auto;
    max-width: 90%;
    min-width: 220px;
    width: 340px;
    height: fit-content;
    margin:auto auto;
    border: 0px solid rgba(128, 128, 128, 0.245);
    border-radius: 5px;
    box-shadow: 1px 1px 10px #2a4264 ;
    background-color: white;
}
.modal-body{
    padding: 10px;
    margin: 0;
}
.modal-body-header{
    margin: 0px;
    padding: 0px;
}
.modal-body-content{
    font-weight: 500;
}
.modal-btn-container{
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
.btn-mdl{
    width: 30%;
    border-radius: 5px;
    color: white;
    height: 40px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 5px;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 100;
    text-transform: capitalize;
}
.btn-mdl-confirm{
background-color: #ff6750;
}
.btn-mdl-cancel{
    width: 30%;
    border-radius: 5px;
    background-color: #2a4264;
}
.capatalize{
    text-transform: capitalize;
}

.modal-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c4c5c5;
}