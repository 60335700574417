.Instruction-header {
  height: 60px;
  background-color: rgb(45, 55, 72);
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 35px;
  font-weight: 900;
  color: white;
  margin-top: 0px;
}
.img-header {
  margin-left: 10px;
  margin-right: 10px;
}
.Instruction-container {
  background-color: #f3fbff;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.clr-red {
  color: rgb(201, 15, 15);
}
.Instruction-body-header {
  margin: 20px 0px 0px 25px;
  padding: 0px;
}
.Instruction-body-ol {
  margin: 0px;
  padding: 0px;
}
.Instruction-body-li {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 0px;
  list-style: none;
  max-width: 500px;
}
.Instruction-body-body {
}
.Instruction-body-img {
  max-width: 500px;
  width: 100%;
}
.Instruction-body-img-img {
  object-fit: cover;
  width: 100%;
}
.button-start {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  height: 50px;

  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background-color: rgb(45, 55, 72);
  border-radius: 10px;
  border: 0px;
  outline: none;

  margin-top: 32px;
}

.button-start:hover {
  cursor: pointer;
}

@media screen and (max-width: 260px) {
  .button-start {
    font-size: 18px;
    font-weight: 500;
  }
}
@media screen and (max-width: 220px) {
  .button-start {
    font-size: 15px;
    font-weight: 400;
  }
}
